import React, { useState } from "react";
import { baseUrl } from "../Services/Constants";
import axios from "axios";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { DatePicker,Dropdown,TextField } from "@fluentui/react";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { IIconProps } from "office-ui-fabric-react/lib/Icon";
import { format } from "date-fns";

const QacSaveHistoryDatePiker = ({ activeTab, refresh }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [msgVisible, setMsgVisible] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [brokeropton, setBrokeropton] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [searchText, setSearchText] = useState("");


  const onFromDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, "M/d/yyyy");
      setFromDate(formattedDate);
      setToDate(null); // Reset toDate when fromDate changes
      Dropdowndata();
    }
  };

  const onToDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, "M/d/yyyy");
      setToDate(formattedDate);
    }
  };

  const onBrokerChange = (brokerId) => {
    setSelectedBroker(brokerId);
  };

  const postQACApiCallForDatepicker = async (dateRange, brokerId) => {
    document.body.classList.add("loading-indicator");
    let Token = await processAndUpdateToken(token);
    const headers = {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    };
    const apiUrl = `${baseUrl}/api/ProcedureData/grid-AddQACreports`;

    try {
      // Prepare request payload based on conditions
      const requestData = {
        Status: "QACPending",
        UserName: sessionStorage.getItem("userName"),
        // BrokerId: brokerId, // Include brokerId in the request
      };

      if (searchText) {
        // If searchText is present, include jobid
        requestData.InputDate = '';
        requestData.BrokerId = 0;
        requestData.jobid = searchText;
      } else if (fromDate && toDate && brokerId) {
        requestData.InputDate = dateRange;
        requestData.BrokerId = brokerId;
      }
      else {
        requestData.InputDate = dateRange;
      }


      const response = await axios.post(apiUrl, requestData, { headers });

      if (response.status !== 201) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      refresh();
      if (response.data?.status === 400) {
        setMsgVisible(true);
        setMsgClass("alert error");
        setMsgText("There was an issue with your request.");
        setTimeout(() => {
          setMsgVisible(false);
          setMsgText("");
        }, 3500);
      }
    } catch (error) {
      console.error("API call failed: ", error);
      setMsgVisible(true);
      setMsgClass("alert error");
      setMsgText("An error occurred while processing your request.");
      setTimeout(() => {
        setMsgVisible(false);
        setMsgText("");
      }, 3500);
    } finally {
      document.body.classList.remove("loading-indicator");
    }
  };


  const Dropdowndata = () => {
    var token = sessionStorage.getItem("token");

    if (token && token.length >= 40) {
      document.body.classList.add("loading-indicator");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(baseUrl + "/api/Defaultdatum/GetBrokerNames/GetBrokerNames", {
          headers,
        })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const mappedData = response.data.map(
            ({ BrokerId, VchBrokerName }) => ({
              key: BrokerId.toString(), // Ensure that the key is a string
              text: VchBrokerName,
            })
          );

          setBrokeropton(mappedData);
        })
        .catch((error) => {
          // Handle errors...
        })
        .finally(() => {
          document.body.classList.remove("loading-indicator");
        });
    }
  };
  const handleButtonClick = () => {
    let dateRange;
    if (fromDate && toDate) {
      dateRange = `${fromDate} - ${toDate}`;
    } else if (fromDate) {
      dateRange = fromDate;
    }
      postQACApiCallForDatepicker(dateRange, selectedBroker);
  };


  const handleRefreshClick = () => {

    refresh();
    setFromDate(null);
    setToDate(null);
    setSelectedBroker(null);
    setSearchText("");
  };
  const onSearchTextChange = (e, newValue) => {
    setSearchText(newValue);
  };

  const refreshIcon = { iconName: "Refresh" };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <DatePicker
            style={{ width: "200px", margin: "13px 1px 9px 6px" }}
            placeholder="From Date..."
            onSelectDate={onFromDateChange}
            value={fromDate ? new Date(fromDate) : null} // Control the DatePicker value
          />
          <DatePicker
            style={{ width: "200px", margin: "13px 10px 9px 6px" }}
            placeholder="To Date..."
            onSelectDate={onToDateChange}
            value={toDate ? new Date(toDate) : null} // Control the DatePicker value
            minDate={fromDate ? new Date(fromDate) : null}
            disabled={!fromDate}
          />
          <Dropdown
            placeholder={"Select Broker"}
            selectedKey={selectedBroker}
            onChange={(event, option) => {
              onBrokerChange(option.key);
            }}
            options={brokeropton}
            disabled={!fromDate}
            styles={{
              dropdown: { width: "150px",margin: "13px 10px 13px 0px" },
            }}
          />
          <TextField
            placeholder="Job Id"
            onChange={onSearchTextChange}
            value={searchText}
          />


          {(fromDate && toDate || searchText) && (
            <PrimaryButton onClick={handleButtonClick}
            style={{  margin: "13px 10px 13px 6px" }}>
              Generate
            </PrimaryButton>
          )}


        </div>

        <IconButton
          iconProps={refreshIcon}
          title="Refresh"
          ariaLabel="Refresh"
          onClick={handleRefreshClick}
          style={{
            color: "black",
            margin: "8px 20px 8px 20px",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          }}
        />
      </div>
    </>
  );
};

export default QacSaveHistoryDatePiker;